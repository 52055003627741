import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "../../images/logo-large.svg"
import menuIcon from "../../images/menu.svg"

const NavLink = props => (
  <li>
    <Link className={props.className} to={props.to}>
      {props.children}
    </Link>
  </li>
)

class Header extends Component {
  openSidebar() {
    document.getElementById("sidebar").style.display = "block"
  }

  closeSidebar() {
    document.getElementById("sidebar").style.display = "none"
  }
  render() {
    let page = this.props.page
    return this.props.smallScreen ? (
      <nav className="sidebar">
        <button onClick={this.openSidebar} className="sidebar-button-off">
          <img src={menuIcon} alt="Menu" className="menu-icon"></img>
        </button>
        <div
          class="sidebar-inner"
          style={{ display: "none" }}
          id="sidebar"
          onClick={this.closeSidebar}
        >
          <button className="sidebar-button-on">
            <img src={menuIcon} alt="Menu" className="menu-icon"></img>
          </button>
          <ul>
            <NavLink
              to="/"
              className={page === "home" ? "sidebutton active" : "sidebutton"}
            >
              Home
            </NavLink>
            <NavLink
              to="/projects"
              className={
                page === "projects" ? "sidebutton active" : "sidebutton"
              }
            >
              Projects
            </NavLink>
            <NavLink
              to="/gallery"
              className={
                page === "gallery" ? "sidebutton active" : "sidebutton"
              }
            >
              Gallery
            </NavLink>
            <NavLink
              to="/contact"
              className={
                page === "contact" ? "sidebutton active" : "sidebutton"
              }
            >
              Contact
            </NavLink>
          </ul>
        </div>
        <Link to="/">
          <img src={logo} alt="jorellaf Logo" className="logosmall"></img>
        </Link>
      </nav>
    ) : (
      <header className="header">
        <nav>
          <ul className="fullsize">
            <NavLink
              to="/"
              className={page === "home" ? "navbutton active" : "navbutton"}
            >
              Home
            </NavLink>
            <NavLink
              to="/projects"
              className={page === "projects" ? "navbutton active" : "navbutton"}
            >
              Projects
            </NavLink>
            <NavLink to="/">
              <img src={logo} alt="jorellaf Logo" className="logo"></img>
            </NavLink>
            <NavLink
              to="/gallery"
              className={page === "gallery" ? "navbutton active" : "navbutton"}
            >
              Gallery
            </NavLink>
            <NavLink
              to="/contact"
              className={page === "contact" ? "navbutton active" : "navbutton"}
            >
              Contact
            </NavLink>
          </ul>
        </nav>
      </header>
    )
  }
}
export default Header
