import React, { Component } from "react"

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <p>
          Copyright © {new Date().getFullYear()}
          {` `}jorellaf{` `}|{` `}All rights reserved
        </p>
      </footer>
    )
  }
}
export default Footer
