import React, { Component } from "react"
import Header from "./header"
import Footer from "./footer"

import "../styles/layout.css"
class layout extends Component {
  state = {
    smallScreen: false,
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  render() {
    const { children, page, title } = this.props
    return (
      <React.Fragment>
        <Header page={page} smallScreen={this.state.smallScreen} />
        <main>
          <header>
            <h1 className="title">{title}</h1>
          </header>
          {children}
        </main>
        <Footer />
      </React.Fragment>
    )
  }
}

export default layout
